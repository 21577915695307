import React, { useEffect, useState } from 'react';
import { HomePage } from './HomePage';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { Route, Routes } from 'react-router-dom';
import { FormPage } from './Pages/FormPage';
import { NewFormPage } from './Pages/NewFormPage';
import "./index.css";
import { FormView } from './Pages/FormView';
import { MyFormsPage } from './Pages/MyFormsPage';
import { FormDetailsPage } from './Pages/FormDetailsPage';
import { Button } from '@fluentui/react-components';
import { loginRequest } from './authConfig';

const App = () => {
  const {instance} = useMsal();
  const currentAuth = useIsAuthenticated();
  const [isAuth, setIsAuth] = useState(currentAuth);

  useEffect(() => {
    window.addToken = (token: string, expiry: string) => {
      window.localStorage.setItem('access_token', token)
      setIsAuth(true);
      return 'OK';
    }
    const current = localStorage.getItem('access_token');
    if (current) {
      setIsAuth(true);  
    }
  }, []);

  const loginClick = () => {
    instance.loginRedirect(loginRequest);
  }

  return isAuth ? (
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='/list' element={<MyFormsPage />} />
        <Route path='/list/:id' element={<FormDetailsPage />} />
        <Route path='formPage' element={<FormPage />} />
        <Route path='NewForm' >
          <Route path=':id' element={<NewFormPage />}>
            <Route path=':pageNo' element={<FormView />}></Route>
          </Route>
        </Route>
      </Routes>
  ) : <div><Button onClick={(e) => loginClick()}>Login</Button></div>
};

export default App;